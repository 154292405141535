<template>
  <doctor-app-layout v-if="$user">
    <slot />
  </doctor-app-layout>
</template>

<script>
import appUtils from '../utils/appUtils'

export default {
  name: 'DoctorLayout',
  data () {
    return {
      appUtils
    }
  },
  watch: {
    $user (u) {
      if (!u) {
        this.$router.push({ path: '/login' }).catch(() => {})
      }
    },
    $globalClinic (c) {
      if (c) {
        appUtils.removeFeatureOrgList()
        this.getFeatureWsList()
      } else {
        appUtils.removeFeatureWsList()
      }
    }
  },
  async mounted () {
    if (!this.$user) {
      this.$router.push({ path: '/login' }).catch(() => {})
    }

    await this.getFeatureWsList()
    await this.getFeatureListNonWsById()
  },
  methods: {
    async getFeatureWsList () {
      // let params = {
      //   workspace_id: this.$globalClinic?.id
      // }
      const id = this.$globalClinic?.id
      if (!id) return

      this.loading = true
      this.$rf
        .getRequest('DoctorRequest')
        .getWsFeatureV2(id)
        .then(r => {
          // const featureList = {
          //   ...this.$featureWsList,
          //   ...r.data
          // }
          appUtils.setFeatureWsList(r.data)
          this.$featureWsList = appUtils.getFeatureWsList()
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getFeatureListNonWsById () {
      try {
        this.loading = true
        const res = await this.$rf.getRequest('DoctorRequest').getWSService()
        if (!res?.data) return

        // const featureList = {
        //   ...this.$featureList,
        //   ...res.data
        // }
        appUtils.setFeatureList(res.data)
        this.$featureList = appUtils.getFeatureList()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
