<template>
    <div class="d-flex" id="wrapper" v-if="$user">
        <div style="padding-left: 0">
            <div class="d-none">
                <NavbarHeader :curr_clinic="curr_clinic" :clinics="clinics" :isDoctor="isDoctor" :isAdmin="isAdmin" :isClinic="isClinic" @onShowNoti="()=>{}" @onLogout="()=>{}"></NavbarHeader>
            </div>
            <div>
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
import appUtils from '../utils/appUtils'
import { mixinUser } from '../utils/mixinUser'
import NavbarHeader from '../components/Layouts/NavbarHeader'

export default {
  name: 'DoctorMdtLayout',
  mixins: [mixinUser],
  components: {NavbarHeader},
  computed: {
    isAdmin () {
      return appUtils.isAdmin()
    },
    isClinic () {
      return appUtils.isClinic()
    },
    isDoctor () {
      return appUtils.isDoctor()
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>