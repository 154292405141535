<template>
    <div>
        <slot/>
    </div>
</template>

<script>
  export default {
    name: 'UserLayout',
    mounted () {
    }
  }
</script>
