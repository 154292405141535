<template>
  <div class="overflow-hidden">
    <slot/>
  </div>
</template>
<script>
  export default {
    name: 'EmptyLayout',
    mounted () {

    }
  }
</script>
