<template>
  <!-- <app-layout>
    <slot/>
  </app-layout> -->
  <AdminAppLayout v-if="$user">
    <slot/>
  </AdminAppLayout>
</template>

<script>
  import appUtils from '../../utils/appUtils'
import AdminAppLayout from './AdminAppLayout.vue'

  export default {
    name: 'AdminLayout',
    components: {AdminAppLayout},
    data () {
      return {
        appUtils
      }
    },
    watch: {
      $user (u) {
        if (!u) {
          this.$router.push({path: '/login'}).catch(() => {})
        }
      }
    },
    mounted () {
      if (!this.$user) {
        this.$router.push({path: '/login'}).catch(() => {})
      }
    }
  }
</script>
