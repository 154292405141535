<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'LoginLayout',
  mounted () {

  }
}
</script>
<style scoped>
</style>