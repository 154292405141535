<template>
  <div class="position-relative">
    <NavbarHeader
      :isDoctor="isDoctor"
      :isAdmin="false"
      :extraSidebar="extraSidebar"
      @onShowNoti="onShowNoti"
      @onOpenSidebar="onOpenSidebar"
    ></NavbarHeader>

    <div class="d-flex" id="wrapper" v-if="$user">
      <!-- Sidebar -->
      <div class="sb-overlay bg-white">
        <Sidebar
          :extraSidebar="extraSidebar"
          @onOpenSidebar="onOpenSidebar"
          :isDoctor="isDoctor"
          :isAdmin="false"
          @onLogout="onLogout"
        ></Sidebar>
      </div>
      <!-- Page Content -->
      <div
        :class="extraSidebar ? 'pl-250px' : 'pl-56px'"
        class="overlay-content"
        id="page-content-wrapper"
      >
        <slot />
      </div>
      <!-- /#page-content-wrapper -->
      <!-- <div class="modal fade" id="toast__share" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal--border">
                    <div class="modal-header">
                        <h5 class="modal-title">Thông Báo!!!</h5>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-html="share_html"></div>
                    </div>
                    <div class="modal-footer text-center">
                        <router-link v-if="share_url" :to="share_url" class="btn-l bg-pri bd-pri text-white m-auto" data-dismiss="modal">{{
                            button_title ? button_title : 'Xem Chi Tiết' }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
      -->
    </div>
    <div v-if="isShowingNoti" class="notification-open">
      <div
        class="notification-side p-1 m-0 shadow"
        style="width: 400px"
        id="notification"
      >
        <div>
          <NotificationListing
            v-if="notis && notis.count"
            @onCloseNoti="onShowNoti"
            :notis="notis"
            :loading="loading_noti"
          />
          <div v-if="!loading_noti && !notis.count">
            <div class="card">
              <div
                class="card-body card-body-scrollable card-body-scrollable-shadow"
                style="height: 4.5rem"
              >
                <div class="divide-y-4 notification-tab">
                  <div>
                    <a class="notification-link" href="#">
                      <div class="row p-2">
                        <div class="col-auto">
                          <span>
                            <i
                              class="fa fa-bell text-info"
                              style="font-size: 20px"
                            ></i>
                          </span>
                        </div>
                        <div class="col">
                          <div class="text-truncate">
                            <strong class="txt-pri"
                              >Bạn chưa có thông báo</strong
                            >
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center" v-if="loading_noti">
            <ul class="list-group list-group-flush">
              <li v-for="i in 10" class="list-group-item" :key="i">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="skeleton-avatar"></div>
                  </div>
                  <div class="col-7">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                  <div class="col-2 ms-auto text-end">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        @click="onShowNoti(false)"
        style="cursor: pointer"
        class="w-100 dropdown-backdrop bg-drop"
      ></div>
    </div>
  </div>
</template>
<script>
import NotificationListingAdmin from '../components/Cms/NotificationListingAdmin.vue'
import NavbarHeader from '../components/Layouts/NavbarHeader'
import Sidebar from '../components/Layouts/Sidebar'
import NotificationListing from '../components/NotificationListing'
import appUtils from '../utils/appUtils'
import { mixinUser } from '../utils/mixinUser'

export default {
  name: 'DoctorAppLayout',
  mixins: [mixinUser],
  components: {
    NavbarHeader,
    Sidebar,
    NotificationListing,
    NotificationListingAdmin
  },
  computed: {
    isDoctor () {
      return appUtils.isDoctor()
    }
  },
  data () {
    return {
      loading_noti: false,
      notis: {},
      extraSidebar: !appUtils.checkIsMobile(),
      loading: false,
      checkin_info: null,
      user_id: null,
      button_title: null,
      share_url: '',
      share_html: '',
      isShowingNoti: false,
      version: process.env.NODE_ENV
    }
  },
  created () {
    window.addEventListener('resize', this.setExtraSidebar)
    this.setExtraSidebar()
  },
  watch: {
    isShowingNoti: {
      handler: function () {
        let self = this
        window.$(document).ready(function () {
          const listNoti = document.querySelector('#notification')
          if (listNoti) {
            listNoti.addEventListener('scroll', (e) => {
              if (
                listNoti.scrollTop + listNoti.clientHeight >=
                listNoti.scrollHeight
              ) {
                if (self.notis.count < self.notis.total) {
                  self.getNotis(self.notis.count + 10)
                }
              }
            })
          }
        })
      },
      deep: true
    },
    '$route.name' (name) {
      this.closeSbInMr(name)
    }
  },
  mounted () {
    let self = this
    if (!this.isDoctor) return
    this.closeSbInMr(this.$route.name)
    window.$(document).ready(function () {
      self.user_id = appUtils.getLocalUser().id
      window.$('#modal__appt').on('hidden.bs.modal', function (e) {
        self.checkin_case_id = null
        self.checkin_person_id = null
      })
      window.$('#toast__checkin').on('hidden.bs.modal', function (e) {})
      // self.changeScreen()
    })
    // window.$(window).on('resize', function () {
    //   self.changeScreen()
    // })
  },
  methods: {
    setExtraSidebar () {
      this.extraSidebar = !appUtils.checkIsMobile()
    },
    closeSbInMr (name) {
      if (
        [
          'ERMTreeView',
          'OrganizationConferenceDetail',
          'PaymentManagement',
          'PatientVisitManagement'
        ].includes(name)
      ) {
        this.extraSidebar = false
      }
    },
    changeScreen () {
      // let self = this
      // var size = window.$('body').width()
      // if (size >= 1740) {
      //   self.extraSidebar = true
      // } else {
      //   self.extraSidebar = false
      // }
    },
    onShowNoti (show) {
      let self = this
      if (show) {
        self.getNotis()
        self.softReadAllNoti()
      }
      self.isShowingNoti = show
      // self.extraSidebar = false
      console.log('object admin layout')
    },
    onOpenSidebar () {
      this.extraSidebar = !this.extraSidebar
      this.isShowingNoti = false
    },
    async getNotis (num) {
      let self = this
      self.loading_noti = true
      let params = {
        limit: num || 20,
        sort: 'created_at',
        sort_by: 'desc'
      }
      try {
        let resp = await self.$rf
          .getRequest('DoctorRequest')
          .getNotifications(params)
        self.notis = resp.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading_noti = false
      }
    },
    async softReadAllNoti () {
      await this.$rf
        .getRequest('DoctorRequest')
        .realAllNotifications()
        .catch((e) => {
          console.log(e)
        })
    },
    reload () {
      window.location.reload()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.setExtraSidebar)
  }
}
</script>
<style lang="css" scoped>
.notification-open {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1039;
}

.pl-250px {
  margin-left: 250px;
}

.pl-56px {
  margin-left: 56px;
}
</style>
