<template>
  <app-layout>
    <slot/>
  </app-layout>
</template>

<script>
  import appUtils from '../utils/appUtils'

  export default {
    name: 'ClinicLayout',
    mounted () {
      let self = this

      if (!appUtils.isClinic()) {
        return self.$router.push({path: '/login'})
      }
    }
  }
</script>
