<template>
  <div>
    <NavbarHeader
      :curr_clinic="null"
      :clinics="[]"
      :isDoctor="false"
      :isAdmin="true"
      :extraSidebar="extraSidebar"
      @onShowNoti="onShowNoti"
      @onOpenSidebar="onOpenSidebar"
    ></NavbarHeader>

    <div
      class="d-flex"
      id="wrapper"
      v-if="$user"
    >
      <!-- Sidebar -->
      <div class="sb-overlay bg-white">
        <Sidebar
          :extraSidebar="extraSidebar"
          @onOpenSidebar="onOpenSidebar"
          :isDoctor="false"
          :isAdmin="true"
          @onLogout="onLogout"
        ></Sidebar>
      </div>
      <!-- Page Content -->
      <div
        :class="extraSidebar ? 'pl-250px' : 'pl-56px'"
        class="overlay-content"
        id="page-content-wrapper"
      >
        <slot />
      </div>
    </div>
    <div
      v-if="isShowingNoti"
      class="notification-open"
    >
      <div
        class="notification-side p-1 m-0 shadow"
        style="width: 400px"
        id="notification"
      >
        <div>
          <NotificationListingAdmin
            v-if="notis && notis.count"
            @onCloseNoti="onShowNoti"
            :notis="notis"
            :loading="loading_noti"
          />
          <div v-if="!loading_noti && !notis.count">
            <div class="card">
              <div
                class="
                  card-body card-body-scrollable card-body-scrollable-shadow
                "
                v-bind:style="{ height: '4.5rem' }"
              >
                <div class="divide-y-4 notification-tab">
                  <div>
                    <a
                      class="notification-link"
                      href="#"
                    >
                      <div class="row p-2">
                        <div class="col-auto">
                          <span><i
                              class="fa fa-bell text-info"
                              style="font-size: 20px"
                            ></i></span>
                        </div>
                        <div class="col">
                          <div class="text-truncate">
                            <strong class="txt-pri">Bạn chưa có thông báo</strong>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-center"
            v-if="loading_noti"
          >
            <ul class="list-group list-group-flush">
              <li
                v-for="i in 10"
                class="list-group-item"
                :key="i"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="skeleton-avatar"></div>
                  </div>
                  <div class="col-7">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                  <div class="col-2 ms-auto text-end">
                    <div class="skeleton-line"></div>
                    <div class="skeleton-line"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        @click="onShowNoti(false)"
        style="cursor: pointer"
        class="w-100 dropdown-backdrop bg-drop"
      ></div>
    </div>
  </div>
</template>
<script>
import NavbarHeader from '../../components/Layouts/NavbarHeader'
import Sidebar from '../../components/Layouts/Sidebar'
import { mixinUser } from '../../utils/mixinUser'
import NotificationListingAdmin from '../../components/Cms/NotificationListingAdmin.vue'

export default {
  name: 'AdminAppLayout',
  mixins: [mixinUser],
  components: { NavbarHeader, Sidebar, NotificationListingAdmin },
  data () {
    return {
      loading_noti: false,
      notis: {},
      extraSidebar: true,
      loading: false,
      checkin_info: null,
      button_title: null,
      share_url: '',
      share_html: '',
      isShowingNoti: false,
      version: process.env.NODE_ENV
    }
  },
  watch: {
    isShowingNoti: {
      handler: function () {
        let self = this
        window.$(document).ready(function () {
          const listNoti = document.querySelector('#notification')
          if (listNoti) {
            listNoti.addEventListener('scroll', e => {
              if (listNoti.scrollTop + listNoti.clientHeight >= listNoti.scrollHeight) {
                if (self.notis.count < self.notis.total) {
                  self.getNotis(self.notis.count + 10)
                }
              }
            })
          }
        })
      },
      deep: true
    }
  },
  mounted () {
    let self = this
    self.getCurrentUser()
    window.$(document).ready(function () {
      window.$('#modal__appt').on('hidden.bs.modal', function (e) {
        self.checkin_case_id = null
        self.checkin_person_id = null
      })
      window.$('#toast__checkin').on('hidden.bs.modal', function (e) { })
      // self.changeScreen()
    })
    // window.$(window).on('resize', function () {
    //   self.changeScreen()
    // })
  },
  methods: {
    // changeScreen () {
    //   let self = this
    //   var size = window.$('body').width()
    //   if (size >= 1740) {
    //     self.extraSidebar = true
    //   } else {
    //     self.extraSidebar = false
    //   }
    // },
    onShowNoti (show) {
      let self = this
      if (show) {
        self.getNotis()
      }
      self.isShowingNoti = show
      // self.extraSidebar = false
      console.log('object doctor layout')
    },
    onOpenSidebar () {
      this.extraSidebar = !this.extraSidebar
      this.isShowingNoti = false
    },
    async getNotis (num) {
      let self = this
      self.loading_noti = true
      let params = {
        limit: num || 20,
        sort: 'created_at',
        sort_by: 'desc'
      }
      try {
        let resp = await self.$rf.getRequest('AdminRequest').getNotifications(params)
        self.notis = resp.data
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.loading_noti = false
      }
    },
    reload () {
      window.location.reload()
    }
  }
}
</script>
<style lang="css" scoped>
.notification-open {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1039;
}
.pl-250px {
  margin-left: 250px;
}
.pl-56px {
  margin-left: 56px;
}
</style>