import Vue from 'vue'

const benchmarking = false

const getEThree = (eThree) => {
  const e3kit = eThree || Vue.prototype.$e3kit || window.$e3kit

  if (!e3kit) {
    throw new Error(`eThree not initialized`)
  }

  return e3kit
}

const cleanup = async () => {
  const eThree = getEThree()

  try {
    await eThree.cleanup()
  } catch (err) {
    console.log(`Failed cleaning up: ${err}`)
    throw new Error(err.message)
  }
}

const register = async () => {
  const eThree = getEThree()
  try {
    await cleanup()
    await eThree.register()
    await backupPrivateKey()
  } catch (err) {
    if (err.name === 'IdentityAlreadyExistsError') {
      await restorePrivateKey()
    } else {
      console.log(`Failed registering: ${err}`)
    }
  }
}

const unregister = async () => {
  const eThree = getEThree()

  try {
    await cleanup()
    await eThree.unregister()
    console.log(`Unregistered`)
  } catch (err) {
    if (err.name === 'RegisterRequiredError') {
      console.log(`Failed unregistering: ${err}`)
    }
  }
}

const hasLocalPrivateKey = async () => {
  const eThree = getEThree()
  const hasLocalPrivateKey = await eThree.hasLocalPrivateKey()
  return hasLocalPrivateKey
}

const findUsers = async (identities) => {
  const eThree = getEThree()
  let findUsersResult = null

  try {
    findUsersResult = await eThree.findUsers(identities)
  } catch (err) {
    // console.log(
    //   `Failed looking up ${identities}'s cards with public keys: ${err}`
    // );
    console.log(`Lookup error ${identities}:`, err.lookupResult)
    // console.log(err)
    throw new Error(err)
  }

  return findUsersResult
}

const backupPrivateKey = async (
  password = process.env.VUE_APP_BACKUP_PRIVATE_KEY
) => {
  const eThree = getEThree()

  try {
    await eThree.backupPrivateKey(password)
  } catch (err) {
    console.log(`Failed backing up private key: ${err}`)
  }
}

const changePassword = async (
  oldPassword = process.env.VUE_APP_BACKUP_PRIVATE_KEY,
  newPassword
) => {
  const eThree = getEThree()

  try {
    await eThree.changePassword(oldPassword, newPassword)
  } catch (err) {
    console.log(`Failed changing password: ${err}`)
    throw new Error(err.message)
  }
}

const restorePrivateKey = async (
  password = process.env.VUE_APP_BACKUP_PRIVATE_KEY
) => {
  const eThree = getEThree()
  try {
    await eThree.restorePrivateKey(password)
    console.log(`Restored private key`)
  } catch (err) {
    if (err.name === 'PrivateKeyNoBackupError') {
      console.log(err)
      await rotatePrivateKey()
      await backupPrivateKey(password)
    } else {
      console.log(`Failed restoring private key: ${err}`, err)
    }
  }
}

const rotatePrivateKey = async () => {
  const eThree = getEThree()

  try {
    await eThree.rotatePrivateKey()
    console.log(`Rotated private key instead: ${eThree.identity}`)
  } catch (err) {
    if (err.name === 'PrivateKeyAlreadyExistsError') {
      await eThree.cleanup()
      await restorePrivateKey()
    } else {
      console.log(`Failed rotating private key: ${err}`)
    }
  }
}

const resetPrivateKeyBackup = async () => {
  const eThree = getEThree()

  try {
    await eThree.resetPrivateKeyBackup()
  } catch (err) {
    console.log(`Failed resetting private key backup: ${err}`)
    throw new Error(err.message)
  }
}

const createGroup = async (groupId, participantIds, ownerId) => {
  const eThree = getEThree()
  let group = null

  try {
    const participantCards = await findUsers(participantIds)

    group = await eThree.createGroup(groupId, participantCards)
    console.log(`Created group: ${groupId}`)
    return group
  } catch (err) {
    if (err.name === 'GroupTicketAlreadyExistsError') {
      group = await getGroupCardData(groupId, ownerId)
    } else {
      console.log(`Failed creating group: ${err}`)
    }
    throw new Error(err)
  }
}

const loadGroup = async (groupId, ownerId) => {
  if (!groupId || !ownerId) return

  const eThree = getEThree()
  let group = null

  try {
    const initiatorCard = await findUsers(ownerId)
    if (!initiatorCard) return

    group = await eThree.loadGroup(groupId, initiatorCard)
    // console.log(`Loaded group ${groupId}:`, group);
  } catch (err) {
    if (err.name === 'MissingPrivateKeyError') {
      await loadGroup(groupId, ownerId)
    } else if (
      err.name === 'GroupError' &&
      err.message === 'Current user has no access to the group ticket'
    ) {
      console.log(err.message)
      // await deleteGroup(groupId);
      // return {
      //   reCreate: true,
      // };
    } else {
      console.log(`Failed loading group data: ${err}`)
    }
    throw new Error(err.message)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return group
  }
}

const getGroup = async (groupId) => {
  if (!groupId) return

  const eThree = getEThree()

  let group = null

  try {
    group = await eThree.getGroup(groupId)
  } catch (err) {
    console.log(`Failed getting group: ${err}`)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return group
  }
}

const getGroupCardData = async (groupId, ownerId) => {
  if (!groupId || !ownerId) return

  let group = null

  try {
    group = await getGroup(groupId)

    if (!group || group === null) {
      group = await loadGroup(groupId, ownerId)
    }
  } catch (err) {
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return group
  }
}

const deleteGroup = async (groupId) => {
  const eThree = getEThree()

  try {
    await eThree.deleteGroup(groupId)
    console.log(`Deleted group: ${groupId}`)
  } catch (err) {
    console.log(`Failed deleting group: ${err}`)
    throw new Error(err.message)
  }
}

const authEncrypt = async (text, recipientCards) => {
  const eThree = getEThree()

  let encryptedText = null
  let repetitions = benchmarking ? 100 : 1

  try {
    for (let i = 0; i < repetitions; i++) {
      encryptedText = await eThree.authEncrypt(text, recipientCards)
    }
  } catch (err) {
    console.log(`Failed encrypting and signing: ${err}`)
    throw new Error(err.message)
  }

  return encryptedText
}

const authDecrypt = async (text, senderCards) => {
  const eThree = getEThree()

  let decryptedText = null
  let repetitions = benchmarking ? 100 : 1

  try {
    for (let i = 0; i < repetitions; i++) {
      decryptedText = await eThree.authDecrypt(text, senderCards)
    }
  } catch (err) {
    console.log(`Failed decrypting and verifying: ${err}`)
    throw new Error(err.message)
  }

  return decryptedText
}

const groupEncrypt = async (text, groupId, ownerId, groupCard) => {
  if (!groupId || groupId === null) return

  if (typeof text !== 'string') return text

  let encryptedText = null
  let repetitions = benchmarking ? 100 : 1

  try {
    let group = groupCard || null

    if (!group) {
      group = await getGroupCardData(groupId, ownerId)
    }

    if (!group) return encryptedText

    for (let i = 0; i < repetitions; i++) {
      encryptedText = await group.encrypt(text)
    }
  } catch (err) {
    console.log(`Failed encrypting and signing: ${err}`)
    throw new Error(err.message)
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return encryptedText
  }
}

const groupDecrypt = async (text, groupId, senderCards, ownerId, groupCard) => {
  if (!groupId || groupId === null) return

  if (typeof text !== 'string') return text

  let decryptedText = null
  let repetitions = benchmarking ? 100 : 1

  try {
    let group = groupCard || null

    if (!group) {
      group = await getGroupCardData(groupId, ownerId)
    }

    if (!group || !senderCards) return decryptedText

    for (let i = 0; i < repetitions; i++) {
      decryptedText = await group.decrypt(text, senderCards)
    }
  } catch (err) {
    console.log(err)
    throw new Error(err.message)
  }

  return decryptedText
}

const e3kit = {
  getEThree,
  cleanup,
  rotatePrivateKey,
  register,
  unregister,
  hasLocalPrivateKey,
  findUsers,
  backupPrivateKey,
  changePassword,
  restorePrivateKey,
  resetPrivateKeyBackup,
  createGroup,
  loadGroup,
  getGroup,
  getGroupCardData,
  deleteGroup,
  authEncrypt,
  authDecrypt,
  groupEncrypt,
  groupDecrypt
}

export default e3kit
