<template>
  <doctor-app-layout v-if="$user">
    <slot />
  </doctor-app-layout>
</template>

  <script>
import orgRole from '../constants/orgRole'
import appUtils from '../utils/appUtils'
import { APP_MODE } from '../utils/constants'

export default {
  name: 'OrganizationLayout',
  data () {
    return {
      appUtils
    }
  },
  watch: {
    $user (u) {
      if (!u) {
        this.$router.push({ path: '/login' }).catch(() => {})
      } else {
        this.checkUserRole()
      }
    },

    $appMode () {
      this.checkUserRole()
    },
    $globalOrg: {
      deep: true,
      handler () {
        this.getFeatureOrgList()
      }
    }
  },
  mounted () {
    this.getFeatureOrgList()

    if (!this.$user) {
      this.$router.push({ path: '/login' }).catch(() => {})
    } else {
      this.checkUserRole()
    }
  },
  methods: {
    handleGetUserRole () {
      const userInfo = appUtils.getLocalUser()
      if (userInfo) {
        const hasOwnerOrAdmin = [orgRole.Owner, orgRole.Admin].some(el =>
          userInfo?.members?.map(m => m.role).includes(el)
        )
        return hasOwnerOrAdmin
      }
    },
    checkUserRole () {
      const isOrganizationMode = this.$appMode === APP_MODE.organization
      if (!isOrganizationMode || !this.handleGetUserRole()) {
        this.$router.push({ path: '/' })
      }
    },
    async getFeatureOrgList () {
      // let params = {
      //   workspace_id: this.$globalClinic?.id
      // }
      const id = this.$globalOrg?.id
      if (!id) return

      this.loading = true
      this.$rf
        .getRequest('DoctorRequest')
        .getOrgFeature(id)
        .then(r => {
          // const featureList = {
          //   ...this.$featureOrgList,
          //   ...r.data
          // }
          appUtils.setFeatureOrgList(r.data)
          this.$featureOrgList = appUtils.getFeatureOrgList()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
